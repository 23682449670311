define("open-event-frontend/templates/public/session/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "CXzLNwbi",
    "block": "{\"symbols\":[\"fav\"],\"statements\":[[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n  \"],[8,\"public/session-item\",[],[[\"@session\",\"@timezone\",\"@expanded\",\"@hideSessionLink\"],[[32,0,[\"model\"]],[32,0,[\"model\",\"event\",\"timezone\"]],true,true]],null],[2,\"\\n\\n\"],[6,[37,1],[[32,0,[\"model\",\"favourites\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"ui divider\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui header\"],[12],[1,[30,[36,3],[\"Attendees\"],null]],[2,\" (\"],[1,[32,0,[\"model\",\"favourites\",\"length\"]]],[2,\")\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"d-flex wrap\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[32,0,[\"model\",\"favourites\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"a\"],[15,6,[30,[36,1],[[32,1,[\"user\",\"isProfilePublic\"]],[30,[36,0],[\"public.sessions.user.view\",[32,1,[\"user\",\"id\"]]],null],\"#\"],null]],[14,0,\"pointer\"],[15,\"data-tooltip\",[32,1,[\"user\",\"resolvedName\"]]],[12],[2,\"\\n          \"],[10,\"img\"],[14,0,\"ui tiny avatar image mr-2 mb-2\"],[15,\"src\",[30,[36,2],[[32,1,[\"user\",\"thumbnailImageUrl\"]],[32,1,[\"user\",\"avatarUrl\"]],\"/images/placeholders/avatar.png\"],null]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"href-to\",\"if\",\"or\",\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/session/view.hbs"
    }
  });

  _exports.default = _default;
});