define("open-event-frontend/templates/groups/edit/events", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "wIzf01fs",
    "block": "{\"symbols\":[],\"statements\":[[8,\"group-nav\",[],[[\"@group\"],[[32,0,[\"model\",\"group\"]]]],null],[2,\"\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[8,\"forms/group/group-events-form\",[],[[\"@group\",\"@events\",\"@isLoading\"],[[32,0,[\"model\",\"group\"]],[32,0,[\"model\",\"filteredEvents\"]],[32,0,[\"isLoading\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[8,\"infinity-loader\",[],[[\"@infinityModel\",\"@triggerOffset\",\"@eventDebounce\"],[[32,0,[\"model\",\"filteredEvents\"]],1000,50]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui loading very padded basic segment\"],[12],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[32,0,[\"infintyModel\",\"reachedInfinity\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/groups/edit/events.hbs"
    }
  });

  _exports.default = _default;
});