define("open-event-frontend/templates/components/forms/events/view/edit-session", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KEVMJO+4",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[16,0,[31,[\"ui form \",[30,[36,0],[[32,0,[\"isLoading\"]],\"loading\"],null]]]],[4,[38,1],[[32,0],\"submit\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[30,[36,2],[\"Title\"],null]],[13],[2,\"\\n    \"],[8,\"input\",[],[[\"@type\",\"@id\",\"@value\"],[\"text\",\"title\",[32,0,[\"data\",\"title\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[30,[36,2],[\"Short Abstract\"],null]],[13],[2,\"\\n    \"],[8,\"widgets/forms/rich-text-editor\",[],[[\"@value\",\"@name\"],[[32,0,[\"data\",\"shortAbstract\"]],\"shortAbstract\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[30,[36,2],[\"Comments\"],null]],[13],[2,\"\\n    \"],[8,\"widgets/forms/rich-text-editor\",[],[[\"@value\",\"@name\"],[[32,0,[\"data\",\"comments\"]],\"comments\"]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"field\"],[12],[2,\"\\n    \"],[10,\"label\"],[12],[1,[30,[36,2],[\"Slides\"],null]],[13],[2,\"\\n    \"],[8,\"widgets/forms/file-upload\",[],[[\"@fileUrl\",\"@label\",\"@icon\",\"@hint\",\"@maxSizeInKb\",\"@edit\"],[[30,[36,3],[[32,0,[\"data\",\"slidesUrl\"]]],null],[30,[36,2],[\"File\"],null],\"file\",[30,[36,2],[\"Select a file\"],null],[32,0,[\"settings\",\"slideSize\"]],true]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"ui teal submit button update-changes\"],[14,4,\"submit\"],[12],[2,\"\\n    \"],[1,[30,[36,2],[\"Save Session\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"t\",\"mut\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/forms/events/view/edit-session.hbs"
    }
  });

  _exports.default = _default;
});