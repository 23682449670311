define("open-event-frontend/templates/admin/settings/social-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "eDA6a+bA",
    "block": "{\"symbols\":[],\"statements\":[[11,\"form\"],[24,0,\"ui form\"],[24,\"novalidate\",\"\"],[4,[38,0],[[32,0],\"submit\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[8,\"forms/admin/settings/system/social-media-token\",[],[[\"@settings\"],[[32,0,[\"model\"]]]],null],[2,\"\\n  \"],[10,\"button\"],[14,0,\"ui teal wide button\"],[14,4,\"submit\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[\"Save\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/settings/social-media.hbs"
    }
  });

  _exports.default = _default;
});