define("open-event-frontend/templates/components/public/side-menu-outer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "LWqtKgFL",
    "block": "{\"symbols\":[\"@event\"],\"statements\":[[6,[37,0],[[30,[36,2],[[30,[36,1],[[32,0,[\"session\",\"currentRouteName\"]],\"public.cfs.new-session\"],null],[30,[36,1],[[32,0,[\"session\",\"currentRouteName\"]],\"public.cfs.new-speaker\"],null],[30,[36,1],[[32,0,[\"session\",\"currentRouteName\"]],\"public.cfs.edit-speaker\"],null],[30,[36,1],[[32,0,[\"session\",\"currentRouteName\"]],\"public.cfs.edit-session\"],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,0,[\"device\",\"isMobile\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[8,\"ui-dropdown\",[[24,0,\"ui selection dropdown fluid vertical\"]],[[\"@selected\"],[[32,0,[\"activeMenuSection\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"default text\"],[12],[1,[32,0,[\"activeMenu\"]]],[13],[2,\"\\n      \"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,5,\"max-height: none !important;\"],[14,0,\"menu\"],[12],[2,\"\\n        \"],[8,\"public/side-menu\",[],[[\"@activeMenuSection\",\"@activeSection\",\"@event\"],[[32,0,[\"activeMenuSection\"]],[32,0,[\"activeSection\"]],[32,1]]],null],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"ui fluid vertical pointing menu\"],[12],[2,\"\\n      \"],[8,\"public/side-menu\",[],[[\"@activeMenuSection\",\"@activeSection\",\"@event\"],[[32,0,[\"activeMenuSection\"]],[32,0,[\"activeSection\"]],[32,1]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"not-eq\",\"and\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/public/side-menu-outer.hbs"
    }
  });

  _exports.default = _default;
});