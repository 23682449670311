define("open-event-frontend/templates/components/group-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X1O3KTG4",
    "block": "{\"symbols\":[\"@isRoomModalOpen\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui grid space-between items-center mt-4 mb-4\"],[12],[2,\"\\n      \"],[10,\"h2\"],[12],[2,\"\\n        \"],[1,[30,[36,0],[\"Groups\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"groups.create\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[11,\"button\"],[24,0,\"ui blue button\"],[4,[38,2],[[32,0],[30,[36,1],[[32,1]],null],true],null],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"Create Group\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[8,\"tabbed-navigation\",[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"groups.list\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"My Groups\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"item\"]],[[\"@route\"],[\"groups.following\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"Following\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"mut\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/group-header.hbs"
    }
  });

  _exports.default = _default;
});