define("open-event-frontend/models/custom-form", ["exports", "open-event-frontend/utils/text", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships"], function (_exports, _text, _attr, _base, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SESSION_FORM_ORDER = _exports.SESSION_FORM_FIELDS = _exports.SPEAKER_FORM_ORDER = _exports.SPEAKER_FORM_FIELDS = void 0;
  var SPEAKER_FORM_FIELDS = {
    name: _text.tn.t('Name'),
    email: _text.tn.t('Email'),
    position: _text.tn.t('Position'),
    organisation: _text.tn.t('Organisation'),
    address: _text.tn.t('Address'),
    city: _text.tn.t('City'),
    country: _text.tn.t('Country'),
    mobile: _text.tn.t('Mobile'),
    gender: _text.tn.t('Gender'),
    shortBiography: _text.tn.t('Short Biography'),
    longBiography: _text.tn.t('Long Biography'),
    speakingExperience: _text.tn.t('Speaking Experience'),
    photoUrl: _text.tn.t('Photo'),
    website: _text.tn.t('Website'),
    github: _text.tn.t('GitHub'),
    facebook: _text.tn.t('Facebook'),
    twitter: _text.tn.t('Twitter'),
    instagram: _text.tn.t('Instagram'),
    mastodon: _text.tn.t('Mastodon'),
    linkedin: _text.tn.t('Linkedin'),
    heardFrom: _text.tn.t('Heard From'),
    sponsorshipRequired: _text.tn.t('Sponsorship Required')
  };
  _exports.SPEAKER_FORM_FIELDS = SPEAKER_FORM_FIELDS;
  var SPEAKER_FORM_ORDER = Object.keys(SPEAKER_FORM_FIELDS);
  _exports.SPEAKER_FORM_ORDER = SPEAKER_FORM_ORDER;
  var SESSION_FORM_FIELDS = {
    title: _text.tn.t('Title'),
    subtitle: _text.tn.t('Subtitle'),
    track: _text.tn.t('Track'),
    sessionType: _text.tn.t('Session Type'),
    shortAbstract: _text.tn.t('Short Abstract'),
    longAbstract: _text.tn.t('Long Abstract'),
    language: _text.tn.t('Language'),
    level: _text.tn.t('Level'),
    slidesUrl: _text.tn.t('Slides'),
    videoUrl: _text.tn.t('Video'),
    audioUrl: _text.tn.t('Audio'),
    website: _text.tn.t('Website'),
    github: _text.tn.t('GitHub'),
    facebook: _text.tn.t('Facebook'),
    twitter: _text.tn.t('Twitter'),
    instagram: _text.tn.t('Instagram'),
    linkedin: _text.tn.t('Linkedin'),
    gitlab: _text.tn.t('GitLab'),
    mastodon: _text.tn.t('Mastodon'),
    comments: _text.tn.t('Comments')
  };
  _exports.SESSION_FORM_FIELDS = SESSION_FORM_FIELDS;
  var SESSION_FORM_ORDER = Object.keys(SESSION_FORM_FIELDS);
  _exports.SESSION_FORM_ORDER = SESSION_FORM_ORDER;

  var _default = _base.default.extend({
    fieldIdentifier: (0, _attr.default)('string'),
    form: (0, _attr.default)('string'),
    type: (0, _attr.default)('string', {
      defaultValue: 'text'
    }),
    name: (0, _attr.default)('string'),
    isRequired: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isPublic: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isIncluded: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    isFixed: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    position: (0, _attr.default)('number'),
    isComplex: (0, _attr.default)('boolean', {
      defaultValue: false
    }),
    description: (0, _attr.default)('string', {
      defaultValue: 'text'
    }),
    event: (0, _relationships.belongsTo)('event'),
    customFormOptions: (0, _relationships.hasMany)('customFormOptions'),
    session: SESSION_FORM_FIELDS,
    speaker: SPEAKER_FORM_FIELDS,
    attendee: {
      firstname: _text.tn.t('First Name'),
      lastname: _text.tn.t('Last Name'),
      email: _text.tn.t('Email'),
      address: _text.tn.t('Address (Street, Building, Number etc.)'),
      city: _text.tn.t('City'),
      state: _text.tn.t('State'),
      country: _text.tn.t('Country'),
      jobTitle: _text.tn.t('Job Title'),
      phone: _text.tn.t('Phone'),
      taxBusinessInfo: _text.tn.t('Tax Business Info'),
      company: _text.tn.t('Organisation'),
      workPhone: _text.tn.t('Work Phone'),
      website: _text.tn.t('Website'),
      blog: _text.tn.t('Blog'),
      twitter: _text.tn.t('Twitter'),
      facebook: _text.tn.t('Facebook'),
      github: _text.tn.t('GitHub'),
      instagram: _text.tn.t('Instagram'),
      linkedin: _text.tn.t('LinkedIn'),
      mastodon: _text.tn.t('Mastodon'),
      gender: _text.tn.t('Gender'),
      acceptReceiveEmails: _text.tn.t('Email consent'),
      acceptVideoRecording: _text.tn.t('Photo & video & text consent'),
      acceptShareDetails: _text.tn.t('Partner contact consent'),
      ageGroup: _text.tn.t('Age Group')
    },
    ready: function ready() {
      if (!this.name) {
        this.name = this[this.form][this.fieldIdentifier];
      }
    },
    identifierPath: Ember.computed('isComplex', function () {
      return !this.isComplex ? this.fieldIdentifier : 'complexFieldValues.' + this.fieldIdentifier;
    }),
    isLongText: Ember.computed('type', function () {
      return this.type === 'text' && ['shortBiography', 'longBiography', 'longAbstract', 'shortAbstract', 'comments', 'speakingExperience'].includes(this.fieldIdentifier);
    }),
    isIncludedObserver: Ember.observer('isIncluded', function () {
      if (!this.isIncluded && this.isRequired) {
        this.set('isRequired', false);
      }
    }),
    isUrlField: Ember.computed('type', 'fieldIdentifier', function () {
      return this.type === 'text' && ['website', 'twitter', 'github', 'gitlab', 'mastodon', 'facebook', 'linkedin', 'slidesUrl', 'instagram', 'videoUrl', 'audioUrl'].includes(this.fieldIdentifier);
    }),
    isRequiredObserver: Ember.observer('isRequired', function () {
      if (!this.isIncluded && this.isRequired) {
        this.set('isIncluded', true);
      }
    })
  });

  _exports.default = _default;
});