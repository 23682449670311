define("open-event-frontend/templates/components/ui-table/cell/admin/cell-recording-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "fxE55bZl",
    "block": "{\"symbols\":[\"@record\",\"@props\"],\"statements\":[[10,\"div\"],[14,0,\"ui horizontal compact basic buttons\"],[12],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@content\",\"@click\",\"@class\",\"@position\"],[[30,[36,0],[\"Delete \"],null],[30,[36,1],[[32,0],[30,[36,2],[[30,[36,0],[\"Are you sure you would like to delete this video recording?\"],null],[30,[36,1],[[32,0],[32,2,[\"actions\",\"deleteRecording\"]],[32,1]],null]],null]],null],\"ui icon button\",\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"i\"],[14,0,\"trash icon\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\",\"confirm\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/cell-recording-action.hbs"
    }
  });

  _exports.default = _default;
});