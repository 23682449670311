define("open-event-frontend/templates/components/url-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "MgAXsTjH",
    "block": "{\"symbols\":[\"@url\"],\"statements\":[[10,\"div\"],[14,0,\"ui action input\"],[14,5,\"width: 100%; height: 36px\"],[12],[2,\"\\n  \"],[10,\"input\"],[14,0,\"truncate\"],[14,5,\"flex: 1 0 !important\"],[15,2,[32,1]],[15,\"placeholder\",[32,1]],[14,\"readonly\",\"\"],[14,4,\"text\"],[12],[13],[2,\"\\n  \"],[8,\"ui-popup\",[],[[\"@on\",\"@content\",\"@position\"],[\"click\",[30,[36,0],[\"Link copied to clipboard\"],null],\"bottom right\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[8,\"copy-button\",[],[[\"@clipboardText\",\"@class\"],[[32,1],\"ui grey icon button rounded-none m-0\"]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"i\"],[14,0,\"copy icon\"],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"a\"],[14,1,\"public_url\"],[15,6,[31,[[32,1]]]],[14,\"target\",\"_blank\"],[14,\"rel\",\"noopener\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui blue icon button ml-auto rounded-l-none m-0\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"share icon\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/url-box.hbs"
    }
  });

  _exports.default = _default;
});