define("open-event-frontend/components/modals/tax-info-modal", ["exports", "jquery", "open-event-frontend/components/modals/modal-base", "open-event-frontend/mixins/form", "open-event-frontend/utils/dictionary/demography", "lodash-es"], function (_exports, _jquery, _modalBase, _form, _demography, _lodashEs) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modalBase.default.extend(_form.default, {
    isSmall: false,
    autoScrollToErrors: true,
    isTaxIncludedInPrice: 'include',
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          taxName: {
            identifier: 'tax_name',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please give a name')
            }]
          },
          taxRate: {
            identifier: 'tax_rate',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please tell us your tax rate (in %)')
            }, {
              type: 'number',
              prompt: this.l10n.t('Please give a valid tax rate')
            }]
          },
          taxId: {
            identifier: 'tax_id',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please give us your tax ID')
            }]
          },
          taxInvoiceCompany: {
            identifier: 'tax_invoice_company',
            optional: true,
            depends: 'send_tax_invoices',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please give us your organisation name')
            }]
          },
          taxInvoiceAddress: {
            identifier: 'tax_invoice_address',
            optional: true,
            depends: 'send_tax_invoices',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please give us your address')
            }]
          },
          taxInvoiceCity: {
            identifier: 'tax_invoice_city',
            optional: true,
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please give a city')
            }]
          },
          taxInvoiceState: {
            identifier: 'tax_invoice_state',
            optional: true,
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please give a state')
            }]
          },
          taxInvoiceZipCode: {
            identifier: 'tax_invoice_zipcode',
            optional: true,
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please provide a zip code')
            }]
          },
          taxCountry: {
            identifier: 'tax_country',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please select your country')
            }]
          }
        }
      };
    },
    countries: Ember.computed(function () {
      return (0, _lodashEs.orderBy)(_demography.countries, 'name');
    }),
    onVisible: function onVisible() {
      // TODO(Areeb): Revert to ES6 getter when a solution is found
      this.set('isTaxIncludedInPrice', this.tax.get('isTaxIncludedInPrice') ? 'include' : 'add');
    },
    actions: {
      updateTaxInfo: function updateTaxInfo() {
        var $form = (0, _jquery.default)('.ui.form', this.element);
        $form.form('validate form');

        if ($form.form('is valid')) {
          this.set('tax.isTaxIncludedInPrice', this.isTaxIncludedInPrice === 'include');
          this.close();
          this.set('hasTaxInfo', true);
        }
      }
    }
  });

  _exports.default = _default;
});