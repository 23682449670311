define("open-event-frontend/models/message-setting", ["exports", "open-event-frontend/models/base", "ember-data/attr"], function (_exports, _base, _attr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    /**
     * Attributes
     */
    action: (0, _attr.default)('string'),
    enabled: (0, _attr.default)('boolean'),
    emailMessage: (0, _attr.default)('string'),
    recipient: (0, _attr.default)('string'),
    emailSubject: (0, _attr.default)('string')
  });

  _exports.default = _default;
});