define("open-event-frontend/components/forms/wizard/sessions-speakers-step", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/mixins/event-wizard", "lodash-es", "open-event-frontend/utils/sort", "open-event-frontend/models/custom-form", "moment", "jquery"], function (_exports, _form, _eventWizard, _lodashEs, _sort, _customForm, _moment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_eventWizard.default, _form.default, {
    // TODO: Removing the Session & Speaker Time Validations due to the weird and buggy behaviour. Will be restored once a perfect solution is found. Please check issue: https://github.com/fossasia/open-event-frontend/issues/3667
    getValidationRules: function getValidationRules() {
      var _this = this;

      _jquery.default.fn.form.settings.rules.checkStartDateCFS = function () {
        return !(0, _moment.default)((0, _jquery.default)('.ui.form').form('get value', 'start_date')).isAfter(_this.data.event.startsAtDate);
      };

      _jquery.default.fn.form.settings.rules.checkEndDateCFS = function () {
        return !(0, _moment.default)((0, _jquery.default)('.ui.form').form('get value', 'end_date')).isAfter(_this.data.event.startsAtDate);
      };

      _jquery.default.fn.form.settings.rules.checkSoftEndDateAfterCfsStart = function () {
        return (0, _moment.default)((0, _jquery.default)('.ui.form').form('get value', 'soft_end_date')).isSameOrAfter(_this.data.speakersCall.startsAtDate);
      };

      _jquery.default.fn.form.settings.rules.checkSoftEndDateBeforeCfsEnd = function () {
        return (0, _moment.default)((0, _jquery.default)('.ui.form').form('get value', 'soft_end_date')).isSameOrBefore(_this.data.speakersCall.endsAtDate);
      };

      _jquery.default.fn.form.settings.rules.checkSoftEndTimeAfterCfsStart = function () {
        return (0, _moment.default)((0, _jquery.default)('.ui.form').form('get value', 'soft_end_date') + ' ' + (0, _jquery.default)('.ui.form').form('get value', 'soft_end_time')).isAfter(_this.data.speakersCall.startsAt);
      };

      _jquery.default.fn.form.settings.rules.checkSoftEndTimeBeforeCfsEnd = function () {
        return (0, _moment.default)((0, _jquery.default)('.ui.form').form('get value', 'soft_end_date') + ' ' + (0, _jquery.default)('.ui.form').form('get value', 'soft_end_time')).isBefore(_this.data.speakersCall.endsAt);
      };

      return {
        inline: true,
        delay: false,
        on: 'change',
        fields: {
          sessionType: {
            identifier: 'session',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter name for session-type')
            }]
          },
          track: {
            identifier: 'track',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter name for track')
            }]
          },
          privateLink: {
            identifier: 'private_link',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a Private link')
            }]
          },
          startDate: {
            identifier: 'start_date',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please tell us when your event starts')
            }, {
              type: 'checkStartDateCFS',
              prompt: this.l10n.t('CFS start time should be before than event start time')
            }]
          },
          softEndsAtDate: {
            identifier: 'soft_end_date',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please give a soft end date')
            }, {
              type: 'checkSoftEndDateAfterCfsStart',
              prompt: this.l10n.t('Soft closing date should be after CFS start date')
            }, {
              type: 'checkSoftEndDateBeforeCfsEnd',
              prompt: this.l10n.t('Soft closing date should be before CFS end date')
            }]
          },
          endDate: {
            identifier: 'end_date',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please tell us when your event ends')
            }, {
              type: 'checkEndDateCFS',
              prompt: this.l10n.t('CFS end time should be before than event start time')
            }]
          },
          startTime: {
            identifier: 'start_time',
            depends: 'start_date',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please give a start time')
            }]
          },
          softEndsAtTime: {
            identifier: 'soft_end_time',
            depends: 'soft_end_date',
            optional: true,
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please give a soft end time')
            }, {
              type: 'checkSoftEndTimeAfterCfsStart',
              prompt: this.l10n.t('Soft closing time should be after CFS start time')
            }, {
              type: 'checkSoftEndTimeBeforeCfsEnd',
              prompt: this.l10n.t('Soft closing time should be before CFS end time')
            }]
          },
          endTime: {
            identifier: 'end_time',
            depends: 'end_date',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please give an end time')
            }]
          },
          microlocation: {
            identifier: 'microlocation',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter name for microlocation')
            }]
          },
          microlocationFloor: {
            identifier: 'microlocation_floor',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter floor for microlocation')
            }]
          },
          announcement: {
            identifier: 'announcement',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please add announcement to enable call for speakers')
            }]
          }
        }
      };
    },
    tracks: Ember.computed('data.tracks.@each.isDeleted', function () {
      return this.data.tracks.filterBy('isDeleted', false);
    }),
    sessionTypes: Ember.computed('data.sessionTypes.@each.isDeleted', function () {
      return this.data.sessionTypes.filterBy('isDeleted', false);
    }),
    hasCallForSpeaker: Ember.computed('data.event.isCfsEnabled', function () {
      return !!this.data.event.isCfsEnabled;
    }),
    hasSoftClosing: Ember.computed('data.speakersCall.softendsAt', function () {
      return !!this.data.speakersCall.softEndsAt;
    }),
    customForm: Ember.computed('data.customForms.[]', function () {
      var grouped = (0, _lodashEs.groupBy)(this.data.customForms.toArray(), function (customForm) {
        return customForm.get('form');
      });
      grouped.speaker = (0, _lodashEs.sortBy)((0, _sort.sortCustomFormFields)(grouped.speaker, _customForm.SPEAKER_FORM_ORDER), ['position']);
      grouped.session = (0, _lodashEs.sortBy)((0, _sort.sortCustomFormFields)(grouped.session, _customForm.SESSION_FORM_ORDER), ['position']);
      return grouped;
    }),
    microlocations: Ember.computed('data.microlocations.@each.isDeleted', 'data.microlocations.@each.position', function () {
      var sortedRooms = this.data.event.microlocations.sortBy('position').filterBy('isDeleted', false);
      sortedRooms.forEach(function (room, idx) {
        room.set('position', idx);
      });
      return sortedRooms;
    }),
    complexCustomForms: Ember.computed('data.customForms.@each.isComplex', function () {
      return this.data.customForms.filterBy('isComplex', true);
    }),
    fieldChanged: function fieldChanged(field) {
      if (!field.get('isIncluded')) {
        field.set('isRequired', false);
      }

      if (field.get('isRequired')) {
        field.set('isIncluded', true);
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.data.event.customForms && !this.data.event.customForms.length) {
        this.set('data.event.customForms', this.getCustomForm(this.data.event));
      }

      if (this.data.event.sessionTypes && !this.data.event.sessionTypes.length) {
        this.data.event.sessionTypes.addObject(this.store.createRecord('session-type'));
      }

      if (this.data.event.tracks && !this.data.event.tracks.length) {
        this.data.event.tracks.addObject(this.store.createRecord('track'));
      }

      if (this.data.event.microlocations && !this.data.event.microlocations.length) {
        this.data.event.microlocations.addObject(this.store.createRecord('microlocation'));
      }
    },
    actions: {
      addItem: function addItem(type) {
        switch (type) {
          case 'sessionType':
            this.data.sessionTypes.addObject(this.store.createRecord('session-type'));
            break;

          case 'track':
            this.data.tracks.addObject(this.store.createRecord('track'));
            break;
        }
      },
      addCustomField: function addCustomField() {
        this.data.customForms.addObject(this.store.createRecord('customForm', {
          event: this.data.event,
          isComplex: true
        }));
      },
      removeField: function removeField(field) {
        this.data.customForms.removeObject(field);
      },
      addRoom: function addRoom(index) {
        this.microlocations.forEach(function (room) {
          var pos = room.get('position');
          pos > index && room.set('position', pos + 1);
        });
        this.data.event.microlocations.addObject(this.store.createRecord('microlocation', {
          position: index + 1
        }));
      },
      removeRoom: function removeRoom(room, index) {
        room.deleteRecord();
        this.microlocations.forEach(function (item) {
          var pos = item.get('position');
          pos > index && item.set('position', pos - 1);
        });
      },
      moveRoom: function moveRoom(item, direction) {
        var idx = item.get('position');
        var otherIdx = direction === 'up' ? idx - 1 : idx + 1;
        var other = this.microlocations.find(function (item) {
          return item.get('position') === otherIdx;
        });
        other.set('position', idx);
        item.set('position', otherIdx);
      },
      resetCFS: function resetCFS() {
        this.set('data.event.isCfsEnabled', !this.data.event.isCfsEnabled);
      },
      resetSoftClosing: function resetSoftClosing() {
        this.set('data.speakersCall.softEndsAt', null);
      },
      onChange: function onChange() {
        this.onValid(function () {});
      }
    }
  });

  _exports.default = _default;
});