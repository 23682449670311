define("open-event-frontend/serializers/application", ["exports", "ember-data/serializers/json-api", "ember-data", "ember-inflector", "open-event-frontend/mixins/event-relation"], function (_exports, _jsonApi, _emberData, _emberInflector, _eventRelation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var normalizeModelName = _emberData.default.normalizeModelName;

  var _default = _jsonApi.default.extend(_eventRelation.default, {
    modelNameFromPayloadKey: function modelNameFromPayloadKey(key) {
      return (0, _emberInflector.singularize)(normalizeModelName(key));
    },
    payloadKeyFromModelName: function payloadKeyFromModelName(modelName) {
      return (0, _emberInflector.singularize)(modelName);
    },
    serializeAttribute: function serializeAttribute(snapshot, json, key, attribute) {
      var _attribute$options, _this$_options;

      if ((_attribute$options = attribute.options) !== null && _attribute$options !== void 0 && _attribute$options.readOnly && !((_this$_options = this._options) !== null && _this$_options !== void 0 && _this$_options.includeReadOnly)) {
        return;
      }

      this._super.apply(this, arguments);
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      if (!snapshot.hasMany(relationship.key) || snapshot.hasMany(relationship.key) && relationship.options.readOnly) {
        return;
      }

      this._super.apply(this, arguments);
    },
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      if (!snapshot.belongsTo(relationship.key) || snapshot.belongsTo(relationship.key) && !snapshot.belongsTo(relationship.key).id) {
        return;
      }

      this._super.apply(this, arguments);
    },
    serialize: function serialize(snapshot, options) {
      this._options = options;
      return this._super.apply(this, arguments);
    }
  });

  _exports.default = _default;
});