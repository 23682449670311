define("open-event-frontend/templates/admin/video/channels/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "sWK0EVjb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[14,0,\"ui header center aligned\"],[12],[1,[30,[36,0],[\"Create Video Channel\"],null]],[13],[2,\"\\n\"],[8,\"forms/admin/video-channel-form\",[],[[\"@videoChannel\",\"@isLoading\"],[[32,0,[\"model\"]],[32,0,[\"isLoading\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/video/channels/create.hbs"
    }
  });

  _exports.default = _default;
});