define("open-event-frontend/templates/events/view/speaker/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w6NvfFb7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column sixteen wide\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"ui header center aligned\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"View Speaker\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui divider\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column sixteen wide\"],[12],[2,\"\\n    \"],[8,\"link-to\",[],[[\"@route\",\"@models\"],[\"events.view.speaker.edit\",[30,[36,1],[[32,0,[\"model\",\"speaker\",\"event\",\"id\"]],[32,0,[\"model\",\"speaker\",\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"button\"],[15,0,[31,[\"ui blue button \",[30,[36,2],[[32,0,[\"device\",\"isMobile\"]],\"fluid\",\"right floated\"],null]]]],[12],[1,[30,[36,0],[\"Edit speaker\"],null]],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column sixteen wide\"],[12],[2,\"\\n    \"],[10,\"h3\"],[14,0,\"ui header\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"Speaker details\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[8,\"events/view/overview/speaker-view\",[],[[\"@fields\",\"@speaker\"],[[32,0,[\"allFields\"]],[32,0,[\"model\",\"speaker\"]]]],null],[2,\"\\n  \"],[10,\"div\"],[14,0,\"weight-600 column three wide\"],[12],[1,[30,[36,0],[\"Sessions\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column thirteen wide\"],[12],[1,[32,0,[\"allSessions\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"array\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/speaker/view.hbs"
    }
  });

  _exports.default = _default;
});