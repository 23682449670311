define("open-event-frontend/templates/components/ui-table/cell/admin/events/event-no-front", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "JzUAB6B8",
    "block": "{\"symbols\":[\"@extraRecords\",\"@record\",\"@props\"],\"statements\":[[8,\"ui-checkbox\",[],[[\"@class\",\"@checked\",\"@onChange\"],[\"toggle\",[32,1,[\"isDemoted\"]],[30,[36,0],[[32,0],[32,3,[\"actions\",\"toggleDemoted\"]],[32,2]],null]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/admin/events/event-no-front.hbs"
    }
  });

  _exports.default = _default;
});