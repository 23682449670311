define("open-event-frontend/models/access-code", ["exports", "ember-data/attr", "open-event-frontend/models/base", "ember-data/relationships", "open-event-frontend/utils/computed-helpers"], function (_exports, _attr, _base, _relationships, _computedHelpers) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    code: (0, _attr.default)('string'),
    accessUrl: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean', {
      defaultValue: true
    }),
    ticketsNumber: (0, _attr.default)('number', {
      defaultValue: 10
    }),
    minQuantity: (0, _attr.default)('number', {
      defaultValue: 0
    }),
    maxQuantity: (0, _attr.default)('number', {
      defaultValue: 10000
    }),
    validFrom: (0, _attr.default)('moment'),
    validTill: (0, _attr.default)('moment'),
    tickets: (0, _relationships.hasMany)('ticket'),
    marketer: (0, _relationships.belongsTo)('user', {
      inverse: 'accessCodes'
    }),
    isExpired: Ember.computed('validTill', function () {
      return new Date() > new Date(this.validTill);
    }),
    event: (0, _relationships.belongsTo)('event', {
      inverse: 'accessCodes'
    }),
    // The event that this access code belongs to
    orders: (0, _relationships.hasMany)('order'),

    /**
     * Computed properties
     */
    validFromDate: _computedHelpers.computedDateTimeSplit.bind(void 0)('validFrom', 'date', 'validTill'),
    validFromTime: _computedHelpers.computedDateTimeSplit.bind(void 0)('validFrom', 'time', 'validTill'),
    validTillDate: _computedHelpers.computedDateTimeSplit.bind(void 0)('validTill', 'date'),
    validTillTime: _computedHelpers.computedDateTimeSplit.bind(void 0)('validTill', 'time')
  });

  _exports.default = _default;
});