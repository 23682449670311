define("open-event-frontend/templates/components/ui-table/cell/cell-tickets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OS6KMAQF",
    "block": "{\"symbols\":[\"ticket\"],\"statements\":[[6,[37,4],[[32,0,[\"record\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui list\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,0,[\"record\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"item\"],[12],[1,[32,1,[\"name\"]]],[2,\" (\"],[1,[30,[36,1],[[32,1,[\"orderStatistics\",\"tickets\",\"completed\"]],[32,1,[\"orderStatistics\",\"tickets\",\"placed\"]]],null]],[2,\"/\"],[1,[32,1,[\"quantity\"]]],[2,\") \"],[10,\"span\"],[14,0,\"item muted text\"],[12],[2,\"[\"],[1,[32,1,[\"type\"]]],[2,\"]\"],[13],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[10,\"div\"],[12],[2,\"Total Sales - \"],[1,[32,0,[\"extraRecords\",\"totalSales\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[1,[30,[36,0],[\"No Ticket Information\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"add\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-tickets.hbs"
    }
  });

  _exports.default = _default;
});