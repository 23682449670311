define("open-event-frontend/templates/public/speakers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "AvgA4zxi",
    "block": "{\"symbols\":[\"speaker\"],\"statements\":[[8,\"tables/utilities/search-box\",[[24,0,\"mb-2 mr-2\"]],[[\"@searchQuery\",\"@size\"],[[32,0,[\"search\"]],\"large\"]],null],[2,\"\\n\"],[10,\"div\"],[15,0,[31,[\"ui grid container \",[30,[36,0],[[32,0,[\"device\",\"isMobile\"]],\"one\",\"three\"],null],\" column\"]]],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"model\",\"speakers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"column speaker-column\"],[12],[2,\"\\n      \"],[8,\"public/speaker-item\",[],[[\"@event\",\"@speaker\",\"@timezone\"],[[32,0,[\"model\",\"event\"]],[32,1],[32,0,[\"model\",\"event\",\"timezone\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[8,\"infinity-loader\",[],[[\"@infinityModel\",\"@triggerOffset\",\"@eventDebounce\"],[[32,0,[\"model\",\"speakers\"]],1000,50]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui loading very padded basic segment\"],[12],[2,\"\\n      \"],[13],[2,\"\\n      \"],[1,[32,0,[\"infintyModel\",\"reachedInfinity\"]]],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/speakers.hbs"
    }
  });

  _exports.default = _default;
});