define("open-event-frontend/helpers/includes-filter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.includesFilter = includesFilter;
  _exports.default = void 0;

  function includesFilter(params) {
    if (params[0] && typeof params[0].includes === 'function') {
      var value = params[1] + ':';
      return params[0].includes(value);
    }

    return false;
  }

  var _default = Ember.Helper.helper(includesFilter);

  _exports.default = _default;
});