define("open-event-frontend/templates/pages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NKrK+vTk",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"public-event ui relaxed grid\"],[12],[2,\"\\n\\t\"],[10,\"div\"],[15,0,[31,[\"sixteen wide column lead \",[30,[36,0],[[32,0,[\"smallLead\"]],\"small\"],null]]]],[14,5,\"height:200px\"],[12],[2,\"\\n    \"],[8,\"widgets/safe-image\",[[24,0,\"background\"]],[[\"@src\"],[\"/images/landing.jpg\"]],null],[2,\"\\n    \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"ui info d-flex\"],[12],[2,\"\\n          \"],[10,\"div\"],[12],[2,\"\\n            \"],[10,\"h1\"],[14,0,\"event name\"],[12],[1,[32,0,[\"model\",\"title\"]]],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui thirteen column container mt-8 mb-8\"],[12],[2,\"\\n  \"],[10,\"div\"],[12],[1,[30,[36,1],[[32,0,[\"model\",\"description\"]]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui center aligned secondary large segment mt-8\"],[14,5,\"margin-bottom:-10px;border:none\"],[12],[2,\"\\n  \"],[1,[30,[36,2],[\"Still have questions?\"],null]],[2,\" \"],[10,\"a\"],[14,6,\"https://support.eventyay.com/\"],[12],[1,[30,[36,2],[\"Visit our FAQ to find answers.\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"sanitize\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/pages.hbs"
    }
  });

  _exports.default = _default;
});