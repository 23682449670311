define("open-event-frontend/templates/events/view/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EctzuweJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui centered grid\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui segment\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"center aligned text\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\" field\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"d-flex\"],[14,5,\"justify-content: center;\"],[12],[2,\"\\n              \"],[8,\"ui-checkbox\",[],[[\"@class\",\"@checked\",\"@onChange\"],[\"ui slider\",[30,[36,1],[[32,0,[\"model\",\"isChatEnabled\"]],\"active\"],null],[30,[36,2],[[32,0],\"toggleChat\"],null]]],null],[2,\"\\n              \"],[10,\"label\"],[14,0,\"weight-300\"],[14,5,\"font-size: large\"],[12],[2,\"\\n                \"],[1,[30,[36,1],[[32,0,[\"model\",\"isChatEnabled\"]],[30,[36,0],[\"Turn off\"],null],[30,[36,0],[\"Turn on\"],null]],null]],[2,\"\\n                \"],[1,[30,[36,0],[\"Event Chat\"],null]],[2,\"\\n              \"],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"model\",\"isChatEnabled\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[10,\"h4\"],[14,0,\"centered text\"],[12],[1,[30,[36,0],[\"Chat is currently enabled for this event. Users can access a chat channel for this event.\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[10,\"h4\"],[14,0,\"centered text\"],[12],[1,[30,[36,0],[\"Chat is currently not enabled for this event. Users cannot access chat for this event.\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/chat.hbs"
    }
  });

  _exports.default = _default;
});