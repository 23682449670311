define("open-event-frontend/templates/events/view/documents", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "alMsppFR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui centered grid\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"column\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui segment\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"center aligned text\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\" field\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"d-flex\"],[14,5,\"justify-content: center;\"],[12],[2,\"\\n              \"],[8,\"ui-checkbox\",[],[[\"@class\",\"@checked\",\"@onChange\"],[\"ui slider\",[30,[36,0],[[32,0,[\"model\",\"isDocumentEnabled\"]],\"active\"],null],[30,[36,1],[[32,0],\"toggleDocument\"],null]]],null],[2,\"\\n              \"],[10,\"label\"],[14,0,\"weight-300\"],[14,5,\"font-size: large\"],[12],[2,\"\\n                \"],[1,[30,[36,0],[[32,0,[\"model\",\"isDocumentEnabled\"]],[30,[36,2],[\"Disable\"],null],[30,[36,2],[\"Enable\"],null]],null]],[2,\"\\n                \"],[1,[30,[36,2],[\"Add Event Documents\"],null]],[2,\"\\n              \"],[13],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"model\",\"isDocumentEnabled\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n          \"],[8,\"forms/document-upload\",[],[[\"@event\"],[[32,0,[\"model\"]]]],null],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/documents.hbs"
    }
  });

  _exports.default = _default;
});