define("open-event-frontend/templates/public/schedule", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NdVeaPdS",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[32,0,[\"model\",\"event\",\"isSchedulePublished\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[[30,[36,1],[[32,0,[\"device\",\"isMobile\"]],\"mt--8\"],null],\" d-flex items-center\"]]],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"d-flex items-center wrap mb-4\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"m-0 mr-4 ui header\"],[14,1,\"session-heading\"],[12],[1,[30,[36,0],[\"Schedule\"],null]],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[8,\"link-to\",[[24,0,\"ui button\"]],[[\"@route\"],[\"public.sessions\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[1,[30,[36,0],[\"List View\"],null]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n    \"],[8,\"widgets/time-zone-picker\",[[24,0,\"mb-2 ml-auto\"]],[[\"@defaultLocal\",\"@eventTimezone\",\"@timezone\"],[[32,0,[\"model\",\"event\",\"online\"]],[32,0,[\"model\",\"event\",\"timezone\"]],[32,0,[\"timezone\"]]]],null],[2,\"\\n    \"],[11,\"button\"],[24,0,\"ui icon button mb-2 ml-2\"],[4,[38,2],[[32,0],\"fullScreen\"],null],[12],[10,\"i\"],[14,0,\"expand large icon\"],[12],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,1,\"fullscreen\"],[14,5,\"background-color: white;overflow-y: scroll;\"],[12],[2,\"\\n    \"],[8,\"schedule\",[],[[\"@timezone\",\"@event\",\"@sessions\",\"@rooms\",\"@height\",\"@isPublic\"],[[32,0,[\"timezone\"]],[32,0,[\"model\",\"event\"]],[32,0,[\"model\",\"sessions\"]],[32,0,[\"microlocations\"]],\"auto\",true]],null],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"ui disabled header\"],[12],[1,[30,[36,0],[\"Schedule for the event is currently unavailable\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/schedule.hbs"
    }
  });

  _exports.default = _default;
});