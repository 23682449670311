define("open-event-frontend/components/modals/admin/content/new-event-sub-topic-modal", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/components/modals/modal-base"], function (_exports, _form, _modalBase) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _modalBase.default.extend(_form.default, {
    isSmall: true,
    autoScrollToErrors: false,
    actions: {
      addEventProperty: function addEventProperty(modelInstance, eventTopic) {
        var _this = this;

        this.onValid(function () {
          modelInstance.set('eventTopic', eventTopic);

          _this.addEventProperty(modelInstance);
        });
      }
    },
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          subTopicName: {
            identifier: 'sub_topic_name',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter a sub topic name')
            }]
          }
        }
      };
    }
  });

  _exports.default = _default;
});