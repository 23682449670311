define("open-event-frontend/templates/components/welcome-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "o0FwI0Cf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"weight-300 lead\"],[12],[1,[30,[36,0],[\"The open source event solution for virtual and in-person events.\"],null]],[13],[2,\"\\n  \"],[8,\"link-to\",[[24,0,\"ui blue button\"]],[[\"@route\"],[\"create\"]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],[\"Create Event\"],null]],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/welcome-header.hbs"
    }
  });

  _exports.default = _default;
});