define("open-event-frontend/utils/dictionary/genders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.genders = void 0;
  var genders = [{
    name: 'Female'
  }, {
    name: 'Male'
  }, {
    name: 'Diverse / Non-binary'
  }, {
    name: 'Other'
  }, {
    name: 'Prefer not to share'
  }];
  _exports.genders = genders;
});