define("open-event-frontend/mixins/event-relation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  /**
   * A mixin that will replace event identifier with the event's original id while saving relationships
   */
  var _default = Ember.Mixin.create({
    serializeBelongsTo: function serializeBelongsTo(snapshot, json, relationship) {
      try {
        var event = snapshot.belongsTo('event');

        if (event) {
          var _event$attributes = event.attributes(),
              originalId = _event$attributes.originalId; // ID may have been already replaced and originalId may be undefined
          // Overriding valid ID with undefined


          if (originalId) {
            event.id = originalId;
          }
        }
      } catch (ignored) {
        /** ignore errors as some models won't be having event relationship **/
      }

      return this._super(snapshot, json, relationship);
    },
    serializeHasMany: function serializeHasMany(snapshot, json, relationship) {
      try {
        if (snapshot.hasMany('events') && snapshot.hasMany('events').length > 0) {
          for (var i = 0; i < snapshot.hasMany('events').length; i++) {
            var event = snapshot.hasMany('events')[i];

            var _event$attributes2 = event.attributes(),
                originalId = _event$attributes2.originalId;

            if (originalId) {
              event.id = originalId;
            }
          }
        }
      } catch (ignored) {
        /** ignore errors as some models won't be having event relationship **/
      }

      return this._super(snapshot, json, relationship);
    }
  });

  _exports.default = _default;
});