define("open-event-frontend/templates/components/modals/confirm-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "0QgekQjf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n  \"],[1,[30,[36,0],[[32,0,[\"confirm\",\"promptText\"]],[32,0,[\"confirm\",\"promptText\"]],[30,[36,2],[\"Are you sure ?\"],null]],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[6,[37,0],[[32,0,[\"confirm\",\"options\",\"extra\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[32,0,[\"confirm\",\"options\",\"extra\"]]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[14,0,\"actions\"],[12],[2,\"\\n\"],[6,[37,4],[[32,0,[\"confirm\",\"options\",\"hideDeny\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,0,[31,[\"ui \",[30,[36,0],[[32,0,[\"confirm\",\"options\",\"denyText\"]],[32,0,[\"confirm\",\"options\",\"denyColor\"]],\"black\"],null],\" button\"]]],[24,4,\"button\"],[4,[38,1],[[32,0],\"deny\"],null],[12],[2,\"\\n      \"],[1,[30,[36,0],[[32,0,[\"confirm\",\"options\",\"denyText\"]],[32,0,[\"confirm\",\"options\",\"denyText\"]],[30,[36,2],[\"No\"],null]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[11,\"button\"],[16,0,[31,[\"ui \",[30,[36,0],[[32,0,[\"confirm\",\"options\",\"denyText\"]],[32,0,[\"confirm\",\"options\",\"approveColor\"]],\"green\"],null],\" button\"]]],[24,4,\"button\"],[4,[38,1],[[32,0],\"confirm\"],null],[12],[2,\"\\n    \"],[1,[30,[36,0],[[32,0,[\"confirm\",\"options\",\"approveText\"]],[32,0,[\"confirm\",\"options\",\"approveText\"]],[30,[36,2],[\"Yes\"],null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"t\",\"sanitize\",\"unless\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/confirm-modal.hbs"
    }
  });

  _exports.default = _default;
});