define("open-event-frontend/utils/dictionary/social-media", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.socialMediaExtraPrefixes = _exports.socialMediaMap = _exports.buttonColor = _exports.socialMediaSites = _exports.socialMediaIdentifiers = _exports.socialMediaNames = void 0;
  var socialMediaNames = ['Facebook', 'Flickr', 'GitHub', 'GitLab', 'Gitter', 'Google Groups', 'Instagram', 'Linkedin', 'Mastodon', 'Patreon', 'Telegram', 'Twitter', 'Vimeo', 'VK', 'Weibo', 'Xing', 'YouTube'];
  _exports.socialMediaNames = socialMediaNames;
  var identifierOverrides = {
    'Google Groups': 'groups.google'
  };
  var socialMediaIdentifiers = socialMediaNames.map(function (name) {
    var _identifierOverrides$;

    return (_identifierOverrides$ = identifierOverrides[name]) !== null && _identifierOverrides$ !== void 0 ? _identifierOverrides$ : name.toLowerCase();
  });
  _exports.socialMediaIdentifiers = socialMediaIdentifiers;
  var prefixOverrides = {
    'gitter': 'https://gitter.im/',
    'telegram': 'https://t.me/'
  };
  var socialMediaSites = socialMediaIdentifiers.map(function (identifier, index) {
    var _prefixOverrides$iden;

    return {
      identifier: identifier,
      name: socialMediaNames[index],
      prefix: (_prefixOverrides$iden = prefixOverrides[identifier]) !== null && _prefixOverrides$iden !== void 0 ? _prefixOverrides$iden : "https://".concat(identifier, ".com/")
    };
  });
  _exports.socialMediaSites = socialMediaSites;
  var buttonColor = {
    github: 'black',
    gitlab: 'orange',
    gitter: 'pink',
    telegram: 'blue',
    patreon: 'red',
    vimeo: 'teal',
    mastodon: 'blue',
    flickr: 'blue',
    xing: 'teal',
    weibo: 'red',
    'google groups': 'blue'
  };
  _exports.buttonColor = buttonColor;
  var socialMediaMap = socialMediaIdentifiers.reduce(function (obj, identifier, index) {
    obj[identifier] = socialMediaSites[index];
    return obj;
  }, {});
  _exports.socialMediaMap = socialMediaMap;
  var socialMediaExtraPrefixes = Object.values(socialMediaMap).reduce(function (obj, media) {
    var _media$prefix;

    obj[(_media$prefix = media.prefix) !== null && _media$prefix !== void 0 ? _media$prefix : media.identifier] = "https://www.".concat(media.identifier, ".com/");
    return obj;
  }, {});
  _exports.socialMediaExtraPrefixes = socialMediaExtraPrefixes;
});