define("open-event-frontend/components/forms/wizard/sponsors-step", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/mixins/event-wizard"], function (_exports, _form, _eventWizard) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, _eventWizard.default, {
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          name: {
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please give your sponsor a name')
            }]
          },
          image_upload: {
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please upload sponsor\'s logo.')
            }]
          }
        }
      };
    },
    sponsors: Ember.computed('data.sponsors.@each.isDeleted', function () {
      return this.data.sponsors.filterBy('isDeleted', false);
    }),
    actions: {
      addSponsor: function addSponsor() {
        var sponsors = this.data.sponsors;
        var incorrect_sponsors = sponsors.filter(function (sponsor) {
          return !sponsor.get('name');
        });

        if (incorrect_sponsors.length > 0) {
          this.notify.error(this.l10n.t('Please fill the required fields for existing sponsor items'), {
            id: 'req_field_sponsor'
          });
          this.set('isLoading', false);
        } else {
          this.data.sponsors.addObject(this.store.createRecord('sponsor'));
        }
      },
      removeSponsor: function removeSponsor(sponsor) {
        sponsor.deleteRecord();
      },
      toggleSponsors: function toggleSponsors() {
        if (this.data.sponsors && !this.data.sponsors.length) {
          this.data.event.isSponsorsEnabled = true;
          this.data.sponsors.addObject(this.store.createRecord('sponsor'));
        } else {
          this.data.event.isSponsorsEnabled = false;
          this.data.sponsors.clear();
        }
      }
    }
  });

  _exports.default = _default;
});