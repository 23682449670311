define("open-event-frontend/templates/events/view/tickets/access-codes/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "w2rQI8AJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui stackable grid\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"ui header column\"],[12],[1,[30,[36,0],[\"Create a New Access code\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n      \"],[8,\"forms/events/view/create-access-code\",[],[[\"@save\",\"@data\",\"@tickets\"],[\"save\",[32,0,[\"model\",\"accessCode\"]],[32,0,[\"model\",\"tickets\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/tickets/access-codes/create.hbs"
    }
  });

  _exports.default = _default;
});