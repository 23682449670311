define("open-event-frontend/adapters/application", ["exports", "open-event-frontend/config/environment", "ember-data/adapters/json-api", "ember-data-has-many-query/mixins/rest-adapter", "ember-data-storefront/mixins/fastboot-adapter"], function (_exports, _environment, _jsonApi, _restAdapter, _fastbootAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.fixFilterQuery = void 0;

  /**
   * The backend server expects the filter in a serialized string format.
   *
   * @param query
   * @return {*}
   */
  var fixFilterQuery = function fixFilterQuery(query) {
    if (Object.prototype.hasOwnProperty.call(query, 'filter')) {
      query.filter = JSON.stringify(query.filter);
    }

    return query;
  };

  _exports.fixFilterQuery = fixFilterQuery;

  var _default = _jsonApi.default.extend(_restAdapter.default, _fastbootAdapter.default, {
    host: _environment.default.APP.apiHost,
    namespace: _environment.default.APP.apiNamespace,
    notify: Ember.inject.service(),
    session: Ember.inject.service(),
    l10n: Ember.inject.service(),
    headers: Ember.computed('session.data.authenticated', function () {
      var headers = {
        'Content-Type': 'application/vnd.api+json'
      };
      var access_token = this.session.data.authenticated.access_token;

      if (access_token) {
        headers[_environment.default['ember-simple-auth-token'].authorizationHeaderName] = _environment.default['ember-simple-auth-token'].authorizationPrefix + access_token;
      }

      return headers;
    }),
    isInvalid: function isInvalid(statusCode) {
      if (statusCode !== 404 && statusCode !== 422 && statusCode !== 403 && statusCode !== 409) {
        this.notify.error(this.l10n.t('An unexpected error has occurred.'), {
          closeAfter: 5000,
          id: 'serve_error'
        });
      }
    },
    query: function query(store, type, _query) {
      _query = fixFilterQuery(_query);
      return this._super(store, type, _query);
    },
    queryRecord: function queryRecord(store, type, query) {
      query = fixFilterQuery(query);
      return this._super(store, type, query);
    },
    ajaxOptions: function ajaxOptions(url, type) {
      var request = this._super.apply(this, arguments); // The requests with public=true will not be authorized


      if (type === 'GET') {
        if (request.data.public) {
          delete request.headers[_environment.default['ember-simple-auth-token'].authorizationHeaderName];
        }

        if (_environment.default.noCache === 'true') {
          request.data.nocache = true;
        }
      }

      return request;
    },

    /**
     This method is called for every response that the adapter receives from the
     API. If the response has a 401 status code it invalidates the session (see
     {{#crossLink "SessionService/invalidate:method"}}{{/crossLink}}).
      @method handleResponse
     @param {Number} status The response status as received from the API
     @param  {Object} headers HTTP headers as received from the API
     @param {any} payload The response body as received from the API
     @param {Object} requestData the original request information
     @protected
     */
    handleResponse: function handleResponse(status, headers, payload, requestData) {
      this.ensureResponseAuthorized(status, headers, payload, requestData);
      return this._super.apply(this, arguments);
    },

    /**
     The default implementation for handleResponse.
     If the response has a 401 status code it invalidates the session (see
     {{#crossLink "SessionService/invalidate:method"}}{{/crossLink}}).
      Override this method if you want custom invalidation logic for incoming responses.
     @method ensureResponseAuthorized
     @param {Number} status The response status as received from the API
     */
    ensureResponseAuthorized: function ensureResponseAuthorized(status) {
      if (status === 401 && this.session.isAuthenticated) {
        this.session.invalidate();
      }
    }
  });

  _exports.default = _default;
});