define("open-event-frontend/templates/public/exhibition/video", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bDmtQk3T",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,1,\"video-root\"],[14,0,\"video-root\"],[12],[2,\"\\n  \"],[8,\"public/stream/jitsi-stream\",[],[[\"@videoStream\"],[[32,0,[\"model\",\"stream\"]]]],null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[8,\"public/stream/side-panel\",[],[[\"@event\"],[[32,0,[\"model\",\"event\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/exhibition/video.hbs"
    }
  });

  _exports.default = _default;
});