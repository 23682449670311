define("open-event-frontend/templates/components/ui-table/cell/events/view/videoroom/cell-video-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "uy3WzDpt",
    "block": "{\"symbols\":[\"@record\"],\"statements\":[[6,[37,2],[[32,1,[\"url\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[32,1,[\"videoChannel\",\"provider\"]],\"bbb\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],[\"Auto-Generated\"],null]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"ui input\"],[14,5,\"width: 100%; height: 36px\"],[12],[2,\"\\n      \"],[10,\"input\"],[14,0,\"truncate\"],[15,2,[32,0,[\"link\"]]],[15,\"placeholder\",[32,0,[\"link\"]]],[14,\"readonly\",\"\"],[14,4,\"text\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"t\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/events/view/videoroom/cell-video-url.hbs"
    }
  });

  _exports.default = _default;
});