define("open-event-frontend/templates/components/ui-table/cell/cell-actions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KZgwliVQ",
    "block": "{\"symbols\":[\"act\",\"@extraRecords\",\"@props\",\"@record\"],\"statements\":[[8,\"link-to\",[],[[\"@route\",\"@model\",\"@class\"],[[31,[[32,3,[\"options\",\"route\"]]]],[32,2,[\"id\"]],\"light-gray-link\"]],[[\"default\"],[{\"statements\":[[1,[32,4]]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"br\"],[12],[13],[10,\"br\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui horizontal compact basic buttons\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[32,3,[\"options\",\"actions\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"route\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[8,\"link-to\",[[24,0,\"ui icon button\"]],[[\"@route\",\"@model\"],[[31,[[32,1,[\"route\"]]]],[32,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[8,\"ui-popup\",[],[[\"@content\",\"@position\"],[[32,1,[\"text\"]],\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n          \"],[10,\"i\"],[15,0,[31,[[32,1,[\"icon\"]],\" icon\"]]],[12],[13],[2,\"\\t\\t\\n        \"]],\"parameters\":[]}]]],[2,\"\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[8,\"ui-popup\",[[24,0,\"ui icon button\"]],[[\"@content\",\"@click\",\"@position\"],[[32,1,[\"text\"]],[30,[36,0],[[32,0],[32,1,[\"method\"]],[32,2,[\"id\"]]],null],\"left center\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        \"],[10,\"i\"],[15,0,[31,[[32,1,[\"icon\"]],\" icon\"]]],[12],[13],[2,\"\\t\\t\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-actions.hbs"
    }
  });

  _exports.default = _default;
});