define("open-event-frontend/components/widgets/forms/time-picker", ["exports", "jquery", "lodash-es", "moment", "open-event-frontend/utils/dictionary/date-time"], function (_exports, _jquery, _lodashEs, _moment, _dateTime) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'calendar', 'time', 'picker', 'input', 'fluid'],
    classNameBindings: ['icon:left', 'icon'],
    icon: true,
    today: true,
    rangePosition: 'none',
    format: _dateTime.FORM_TIME_FORMAT,
    options: {},
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._super.apply(this, arguments);

      var defaultOptions = {
        type: 'time',
        formatter: {
          time: function time(date) {
            if (!date) {
              return '';
            }

            return (0, _moment.default)(date).format(_this.format);
          }
        }
      };
      (0, _jquery.default)(this.element).calendar((0, _lodashEs.merge)(defaultOptions, this.options));
    },
    actions: {
      onChange: function onChange() {
        if (this.onChange) {
          this.onChange(this.value);
        }
      }
    }
  });

  _exports.default = _default;
});