define("open-event-frontend/utils/dictionary/demography", ["exports", "open-event-frontend/utils/text"], function (_exports, _text) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.countries = void 0;

  /**
   * Using a lean list of countries instead of an external dependency.
   * All the libraries have a lot of extra data which is of no use to us at this point and unnecessarily takes up more space.
   */
  var countries = [{
    name: _text.tn.t('Andorra'),
    code: 'AD'
  }, {
    name: _text.tn.t('United Arab Emirates'),
    code: 'AE'
  }, {
    name: _text.tn.t('Afghanistan'),
    code: 'AF'
  }, {
    name: _text.tn.t('Antigua And Barbuda'),
    code: 'AG'
  }, {
    name: _text.tn.t('Anguilla'),
    code: 'AI'
  }, {
    name: _text.tn.t('Albania'),
    code: 'AL'
  }, {
    name: _text.tn.t('Armenia'),
    code: 'AM'
  }, {
    name: _text.tn.t('Angola'),
    code: 'AO'
  }, {
    name: _text.tn.t('Antarctica'),
    code: 'AQ'
  }, {
    name: _text.tn.t('Argentina'),
    code: 'AR'
  }, {
    name: _text.tn.t('American Samoa'),
    code: 'AS'
  }, {
    name: _text.tn.t('Austria'),
    code: 'AT'
  }, {
    name: _text.tn.t('Australia'),
    code: 'AU'
  }, {
    name: _text.tn.t('Aruba'),
    code: 'AW'
  }, {
    name: _text.tn.t('Åland'),
    code: 'AX'
  }, {
    name: _text.tn.t('Azerbaijan'),
    code: 'AZ'
  }, {
    name: _text.tn.t('Bosnia and Herzegovina'),
    code: 'BA'
  }, {
    name: _text.tn.t('Barbados'),
    code: 'BB'
  }, {
    name: _text.tn.t('Bangladesh'),
    code: 'BD'
  }, {
    name: _text.tn.t('Belgium'),
    code: 'BE'
  }, {
    name: _text.tn.t('Burkina Faso'),
    code: 'BF'
  }, {
    name: _text.tn.t('Bulgaria'),
    code: 'BG'
  }, {
    name: _text.tn.t('Bahrain'),
    code: 'BH'
  }, {
    name: _text.tn.t('Burundi'),
    code: 'BI'
  }, {
    name: _text.tn.t('Benin'),
    code: 'BJ'
  }, {
    name: _text.tn.t('Saint Barthélemy'),
    code: 'BL'
  }, {
    name: _text.tn.t('Bermuda'),
    code: 'BM'
  }, {
    name: _text.tn.t('Brunei'),
    code: 'BN'
  }, {
    name: _text.tn.t('Bolivia'),
    code: 'BO'
  }, {
    name: _text.tn.t('Bonaire'),
    code: 'BQ'
  }, {
    name: _text.tn.t('Brazil'),
    code: 'BR'
  }, {
    name: _text.tn.t('Bahamas'),
    code: 'BS'
  }, {
    name: _text.tn.t('Bhutan'),
    code: 'BT'
  }, {
    name: _text.tn.t('Bouvet Island'),
    code: 'BV'
  }, {
    name: _text.tn.t('Botswana'),
    code: 'BW'
  }, {
    name: _text.tn.t('Belarus'),
    code: 'BY'
  }, {
    name: _text.tn.t('Belize'),
    code: 'BZ'
  }, {
    name: _text.tn.t('Canada'),
    code: 'CA'
  }, {
    name: _text.tn.t('Cocos [Keeling] Islands'),
    code: 'CC'
  }, {
    name: _text.tn.t('Democratic Republic Of Congo'),
    code: 'CD'
  }, {
    name: _text.tn.t('Central African Republic'),
    code: 'CF'
  }, {
    name: _text.tn.t('Republic Of the Congo'),
    code: 'CG'
  }, {
    name: _text.tn.t('Switzerland'),
    code: 'CH'
  }, {
    name: _text.tn.t('Ivory Coast'),
    code: 'CI'
  }, {
    name: _text.tn.t('Cook Islands'),
    code: 'CK'
  }, {
    name: _text.tn.t('Chile'),
    code: 'CL'
  }, {
    name: _text.tn.t('Cameroon'),
    code: 'CM'
  }, {
    name: _text.tn.t('China'),
    code: 'CN'
  }, {
    name: _text.tn.t('Colombia'),
    code: 'CO'
  }, {
    name: _text.tn.t('Costa Rica'),
    code: 'CR'
  }, {
    name: _text.tn.t('Cuba'),
    code: 'CU'
  }, {
    name: _text.tn.t('Cape Verde'),
    code: 'CV'
  }, {
    name: _text.tn.t('Curacao'),
    code: 'CW'
  }, {
    name: _text.tn.t('Christmas Island'),
    code: 'CX'
  }, {
    name: _text.tn.t('Cyprus'),
    code: 'CY'
  }, {
    name: _text.tn.t('Czech Republic'),
    code: 'CZ'
  }, {
    name: _text.tn.t('Germany'),
    code: 'DE'
  }, {
    name: _text.tn.t('Djibouti'),
    code: 'DJ'
  }, {
    name: _text.tn.t('Denmark'),
    code: 'DK'
  }, {
    name: _text.tn.t('Dominica'),
    code: 'DM'
  }, {
    name: _text.tn.t('Dominican Republic'),
    code: 'DO'
  }, {
    name: _text.tn.t('Algeria'),
    code: 'DZ'
  }, {
    name: _text.tn.t('Ecuador'),
    code: 'EC'
  }, {
    name: _text.tn.t('Estonia'),
    code: 'EE'
  }, {
    name: _text.tn.t('Egypt'),
    code: 'EG'
  }, {
    name: _text.tn.t('Western Sahara'),
    code: 'EH'
  }, {
    name: _text.tn.t('Eritrea'),
    code: 'ER'
  }, {
    name: _text.tn.t('Spain'),
    code: 'ES'
  }, {
    name: _text.tn.t('Ethiopia'),
    code: 'ET'
  }, {
    name: _text.tn.t('Finland'),
    code: 'FI'
  }, {
    name: _text.tn.t('Fiji'),
    code: 'FJ'
  }, {
    name: _text.tn.t('Falkland Islands'),
    code: 'FK'
  }, {
    name: _text.tn.t('Micronesia'),
    code: 'FM'
  }, {
    name: _text.tn.t('Faroe Islands'),
    code: 'FO'
  }, {
    name: _text.tn.t('France'),
    code: 'FR'
  }, {
    name: _text.tn.t('Gabon'),
    code: 'GA'
  }, {
    name: _text.tn.t('United Kingdom'),
    code: 'GB'
  }, {
    name: _text.tn.t('Grenada'),
    code: 'GD'
  }, {
    name: _text.tn.t('Georgia'),
    code: 'GE'
  }, {
    name: _text.tn.t('French Guiana'),
    code: 'GF'
  }, {
    name: _text.tn.t('Guernsey'),
    code: 'GG'
  }, {
    name: _text.tn.t('Ghana'),
    code: 'GH'
  }, {
    name: _text.tn.t('Gibraltar'),
    code: 'GI'
  }, {
    name: _text.tn.t('Greenland'),
    code: 'GL'
  }, {
    name: _text.tn.t('Gambia'),
    code: 'GM'
  }, {
    name: _text.tn.t('Guinea'),
    code: 'GN'
  }, {
    name: _text.tn.t('Guadeloupe'),
    code: 'GP'
  }, {
    name: _text.tn.t('Equatorial Guinea'),
    code: 'GQ'
  }, {
    name: _text.tn.t('Greece'),
    code: 'GR'
  }, {
    name: _text.tn.t('South Georgia and The South Sandwich Islands'),
    code: 'GS'
  }, {
    name: _text.tn.t('Guatemala'),
    code: 'GT'
  }, {
    name: _text.tn.t('Guam'),
    code: 'GU'
  }, {
    name: _text.tn.t('Guinea-Bissau'),
    code: 'GW'
  }, {
    name: _text.tn.t('Guyana'),
    code: 'GY'
  }, {
    name: _text.tn.t('Hong Kong'),
    code: 'HK'
  }, {
    name: _text.tn.t('Heard Island And McDonald Islands'),
    code: 'HM'
  }, {
    name: _text.tn.t('Honduras'),
    code: 'HN'
  }, {
    name: _text.tn.t('Croatia'),
    code: 'HR'
  }, {
    name: _text.tn.t('Haiti'),
    code: 'HT'
  }, {
    name: _text.tn.t('Hungary'),
    code: 'HU'
  }, {
    name: _text.tn.t('Indonesia'),
    code: 'ID'
  }, {
    name: _text.tn.t('Ireland'),
    code: 'IE'
  }, {
    name: _text.tn.t('Israel'),
    code: 'IL'
  }, {
    name: _text.tn.t('Isle of Man'),
    code: 'IM'
  }, {
    name: _text.tn.t('India'),
    code: 'IN'
  }, {
    name: _text.tn.t('British Indian Ocean Territory'),
    code: 'IO'
  }, {
    name: _text.tn.t('Iraq'),
    code: 'IQ'
  }, {
    name: _text.tn.t('Iran'),
    code: 'IR'
  }, {
    name: _text.tn.t('Iceland'),
    code: 'IS'
  }, {
    name: _text.tn.t('Italy'),
    code: 'IT'
  }, {
    name: _text.tn.t('Jersey'),
    code: 'JE'
  }, {
    name: _text.tn.t('Jamaica'),
    code: 'JM'
  }, {
    name: _text.tn.t('Jordan'),
    code: 'JO'
  }, {
    name: _text.tn.t('Japan'),
    code: 'JP'
  }, {
    name: _text.tn.t('Kenya'),
    code: 'KE'
  }, {
    name: _text.tn.t('Kyrgyzstan'),
    code: 'KG'
  }, {
    name: _text.tn.t('Cambodia'),
    code: 'KH'
  }, {
    name: _text.tn.t('Kiribati'),
    code: 'KI'
  }, {
    name: _text.tn.t('Comoros'),
    code: 'KM'
  }, {
    name: _text.tn.t('Saint Kitts And Nevis'),
    code: 'KN'
  }, {
    name: _text.tn.t('North Korea'),
    code: 'KP'
  }, {
    name: _text.tn.t('South Korea'),
    code: 'KR'
  }, {
    name: _text.tn.t('Kuwait'),
    code: 'KW'
  }, {
    name: _text.tn.t('Cayman Islands'),
    code: 'KY'
  }, {
    name: _text.tn.t('Kazakhstan'),
    code: 'KZ'
  }, {
    name: _text.tn.t('Laos'),
    code: 'LA'
  }, {
    name: _text.tn.t('Lebanon'),
    code: 'LB'
  }, {
    name: _text.tn.t('Saint Lucia'),
    code: 'LC'
  }, {
    name: _text.tn.t('Liechtenstein'),
    code: 'LI'
  }, {
    name: _text.tn.t('Sri Lanka'),
    code: 'LK'
  }, {
    name: _text.tn.t('Liberia'),
    code: 'LR'
  }, {
    name: _text.tn.t('Lesotho'),
    code: 'LS'
  }, {
    name: _text.tn.t('Lithuania'),
    code: 'LT'
  }, {
    name: _text.tn.t('Luxembourg'),
    code: 'LU'
  }, {
    name: _text.tn.t('Latvia'),
    code: 'LV'
  }, {
    name: _text.tn.t('Libya'),
    code: 'LY'
  }, {
    name: _text.tn.t('Morocco'),
    code: 'MA'
  }, {
    name: _text.tn.t('Monaco'),
    code: 'MC'
  }, {
    name: _text.tn.t('Moldova'),
    code: 'MD'
  }, {
    name: _text.tn.t('Montenegro'),
    code: 'ME'
  }, {
    name: _text.tn.t('Saint Martin'),
    code: 'MF'
  }, {
    name: _text.tn.t('Madagascar'),
    code: 'MG'
  }, {
    name: _text.tn.t('Marshall Islands'),
    code: 'MH'
  }, {
    name: _text.tn.t('North Macedonia'),
    code: 'MK'
  }, {
    name: _text.tn.t('Mali'),
    code: 'ML'
  }, {
    name: _text.tn.t('Myanmar'),
    code: 'MM'
  }, {
    name: _text.tn.t('Mongolia'),
    code: 'MN'
  }, {
    name: _text.tn.t('Macao'),
    code: 'MO'
  }, {
    name: _text.tn.t('Northern Mariana Islands'),
    code: 'MP'
  }, {
    name: _text.tn.t('Martinique'),
    code: 'MQ'
  }, {
    name: _text.tn.t('Mauritania'),
    code: 'MR'
  }, {
    name: _text.tn.t('Montserrat'),
    code: 'MS'
  }, {
    name: _text.tn.t('Malta'),
    code: 'MT'
  }, {
    name: _text.tn.t('Mauritius'),
    code: 'MU'
  }, {
    name: _text.tn.t('Maldives'),
    code: 'MV'
  }, {
    name: _text.tn.t('Malawi'),
    code: 'MW'
  }, {
    name: _text.tn.t('Mexico'),
    code: 'MX'
  }, {
    name: _text.tn.t('Malaysia'),
    code: 'MY'
  }, {
    name: _text.tn.t('Mozambique'),
    code: 'MZ'
  }, {
    name: _text.tn.t('Namibia'),
    code: 'NA'
  }, {
    name: _text.tn.t('New Caledonia'),
    code: 'NC'
  }, {
    name: _text.tn.t('Niger'),
    code: 'NE'
  }, {
    name: _text.tn.t('Norfolk Island'),
    code: 'NF'
  }, {
    name: _text.tn.t('Nigeria'),
    code: 'NG'
  }, {
    name: _text.tn.t('Nicaragua'),
    code: 'NI'
  }, {
    name: _text.tn.t('Netherlands'),
    code: 'NL'
  }, {
    name: _text.tn.t('Norway'),
    code: 'NO'
  }, {
    name: _text.tn.t('Nepal'),
    code: 'NP'
  }, {
    name: _text.tn.t('Nauru'),
    code: 'NR'
  }, {
    name: _text.tn.t('Niue'),
    code: 'NU'
  }, {
    name: _text.tn.t('New Zealand'),
    code: 'NZ'
  }, {
    name: _text.tn.t('Oman'),
    code: 'OM'
  }, {
    name: _text.tn.t('Panama'),
    code: 'PA'
  }, {
    name: _text.tn.t('Peru'),
    code: 'PE'
  }, {
    name: _text.tn.t('French Polynesia'),
    code: 'PF'
  }, {
    name: _text.tn.t('Papua New Guinea'),
    code: 'PG'
  }, {
    name: _text.tn.t('Philippines'),
    code: 'PH'
  }, {
    name: _text.tn.t('Pakistan'),
    code: 'PK'
  }, {
    name: _text.tn.t('Poland'),
    code: 'PL'
  }, {
    name: _text.tn.t('Saint Pierre And Miquelon'),
    code: 'PM'
  }, {
    name: _text.tn.t('Pitcairn Islands'),
    code: 'PN'
  }, {
    name: _text.tn.t('Puerto Rico'),
    code: 'PR'
  }, {
    name: _text.tn.t('Palestinian'),
    code: 'PS'
  }, {
    name: _text.tn.t('Portugal'),
    code: 'PT'
  }, {
    name: _text.tn.t('Palau'),
    code: 'PW'
  }, {
    name: _text.tn.t('Paraguay'),
    code: 'PY'
  }, {
    name: _text.tn.t('Qatar'),
    code: 'QA'
  }, {
    name: _text.tn.t('Reunion'),
    code: 'RE'
  }, {
    name: _text.tn.t('Romania'),
    code: 'RO'
  }, {
    name: _text.tn.t('Serbia'),
    code: 'RS'
  }, {
    name: _text.tn.t('Russian'),
    code: 'RU'
  }, {
    name: _text.tn.t('Rwanda'),
    code: 'RW'
  }, {
    name: _text.tn.t('Saudi Arabia'),
    code: 'SA'
  }, {
    name: _text.tn.t('Solomon Islands'),
    code: 'SB'
  }, {
    name: _text.tn.t('Seychelles'),
    code: 'SC'
  }, {
    name: _text.tn.t('Sudan'),
    code: 'SD'
  }, {
    name: _text.tn.t('Sweden'),
    code: 'SE'
  }, {
    name: _text.tn.t('Singapore'),
    code: 'SG'
  }, {
    name: _text.tn.t('Saint Helena'),
    code: 'SH'
  }, {
    name: _text.tn.t('Slovenia'),
    code: 'SI'
  }, {
    name: _text.tn.t('Svalbard And Jan Mayen'),
    code: 'SJ'
  }, {
    name: _text.tn.t('Slovakia'),
    code: 'SK'
  }, {
    name: _text.tn.t('Sierra Leone'),
    code: 'SL'
  }, {
    name: _text.tn.t('San Marino'),
    code: 'SM'
  }, {
    name: _text.tn.t('Senegal'),
    code: 'SN'
  }, {
    name: _text.tn.t('Somalia'),
    code: 'SO'
  }, {
    name: _text.tn.t('Suriname'),
    code: 'SR'
  }, {
    name: _text.tn.t('South Sudan'),
    code: 'SS'
  }, {
    name: _text.tn.t('Sao Tome and Principe'),
    code: 'ST'
  }, {
    name: _text.tn.t('El Salvador'),
    code: 'SV'
  }, {
    name: _text.tn.t('Sint Maarten'),
    code: 'SX'
  }, {
    name: _text.tn.t('Syria'),
    code: 'SY'
  }, {
    name: _text.tn.t('Swaziland'),
    code: 'SZ'
  }, {
    name: _text.tn.t('Turks And Caicos Islands'),
    code: 'TC'
  }, {
    name: _text.tn.t('Chad'),
    code: 'TD'
  }, {
    name: _text.tn.t('French Southern Territories'),
    code: 'TF'
  }, {
    name: _text.tn.t('Togo'),
    code: 'TG'
  }, {
    name: _text.tn.t('Thailand'),
    code: 'TH'
  }, {
    name: _text.tn.t('Tajikistan'),
    code: 'TJ'
  }, {
    name: _text.tn.t('Tokelau'),
    code: 'TK'
  }, {
    name: _text.tn.t('East Timor'),
    code: 'TL'
  }, {
    name: _text.tn.t('Turkmenistan'),
    code: 'TM'
  }, {
    name: _text.tn.t('Tunisia'),
    code: 'TN'
  }, {
    name: _text.tn.t('Tonga'),
    code: 'TO'
  }, {
    name: _text.tn.t('Turkey'),
    code: 'TR'
  }, {
    name: _text.tn.t('Trinidad And Tobago'),
    code: 'TT'
  }, {
    name: _text.tn.t('Tuvalu'),
    code: 'TV'
  }, {
    name: _text.tn.t('Taiwan'),
    code: 'TW'
  }, {
    name: _text.tn.t('Tanzania'),
    code: 'TZ'
  }, {
    name: _text.tn.t('Ukraine'),
    code: 'UA'
  }, {
    name: _text.tn.t('Uganda'),
    code: 'UG'
  }, {
    name: _text.tn.t('United States Minor Outlying Islands'),
    code: 'UM'
  }, {
    name: _text.tn.t('United States'),
    code: 'US'
  }, {
    name: _text.tn.t('Uruguay'),
    code: 'UY'
  }, {
    name: _text.tn.t('Uzbekistan'),
    code: 'UZ'
  }, {
    name: _text.tn.t('Vatican City'),
    code: 'VA'
  }, {
    name: _text.tn.t('Saint Vincent And The Grenadines'),
    code: 'VC'
  }, {
    name: _text.tn.t('Venezuela'),
    code: 'VE'
  }, {
    name: _text.tn.t('British Virgin Islands'),
    code: 'VG'
  }, {
    name: _text.tn.t('U.S. Virgin Islands'),
    code: 'VI'
  }, {
    name: _text.tn.t('Vietnam'),
    code: 'VN'
  }, {
    name: _text.tn.t('Vanuatu'),
    code: 'VU'
  }, {
    name: _text.tn.t('Wallis And Futuna'),
    code: 'WF'
  }, {
    name: _text.tn.t('Samoa'),
    code: 'WS'
  }, {
    name: _text.tn.t('Kosovo'),
    code: 'XK'
  }, {
    name: _text.tn.t('Yemen'),
    code: 'YE'
  }, {
    name: _text.tn.t('Mayotte'),
    code: 'YT'
  }, {
    name: _text.tn.t('South Africa'),
    code: 'ZA'
  }, {
    name: _text.tn.t('Zambia'),
    code: 'ZM'
  }, {
    name: _text.tn.t('Zimbabwe'),
    code: 'ZW'
  }];
  _exports.countries = countries;
});