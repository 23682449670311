define("open-event-frontend/templates/components/orders/after-order-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "+w04WXql",
    "block": "{\"symbols\":[\"@event\"],\"statements\":[[10,\"div\"],[14,0,\"ui segments\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui green inverted segment center aligned\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui inverted mini statistic horizontal\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"value\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"Organizer Message\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui padded segment pre-line\"],[12],[1,[30,[36,1],[[32,1,[\"afterOrderMessage\"]]],null]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"sanitize\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/orders/after-order-message.hbs"
    }
  });

  _exports.default = _default;
});