define("open-event-frontend/templates/components/public/stream/chat", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "dkOYcs9l",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"video-root\"],[4,[38,0],[[32,0,[\"init\"]]],null],[12],[2,\"\\n\"],[6,[37,1],[[32,0,[\"iframeUrl\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"iframe\"],[15,\"src\",[31,[[32,0,[\"iframeUrl\"]]]]],[14,\"title\",\"Rocket Chat\"],[14,5,\"width: 100%; height: 100%; border: 0px;\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"did-insert\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/public/stream/chat.hbs"
    }
  });

  _exports.default = _default;
});