define("open-event-frontend/templates/components/tabbed-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "M29TN+F+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,1],[[32,0,[\"device\",\"isMobile\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,\"role\",\"button\"],[24,0,\"ui segment center aligned\"],[4,[38,0],[[32,0],\"toggleMenu\"],null],[12],[2,\"\\n    \"],[1,[32,0,[\"currentRoute\"]]],[2,\"\\n    \"],[10,\"i\"],[14,0,\"dropdown icon\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[11,\"div\"],[24,\"role\",\"button\"],[16,0,[31,[\"mobile hidden ui fluid stackable \",[30,[36,2],[[32,0,[\"isNonPointing\"]],[30,[36,2],[[32,0,[\"device\",\"isMobile\"]],\"pointing\"],null]],null],\" \",[30,[36,2],[[32,0,[\"device\",\"isMobile\"]],[30,[36,1],[[32,0,[\"isTabbed\"]],\"tabular\",[30,[36,1],[[32,0,[\"isVertical\"]],\"vertical\",\"secondary\"],null]],null]],null],\" menu\"]]],[4,[38,0],[[32,0],\"toggleMenu\",\"reset\"],null],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/tabbed-navigation.hbs"
    }
  });

  _exports.default = _default;
});