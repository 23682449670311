define("open-event-frontend/templates/components/modals/add-to-calendar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "f44+P37j",
    "block": "{\"symbols\":[\"calendar\"],\"statements\":[[10,\"i\"],[14,0,\"black close icon\"],[12],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n    \"],[1,[30,[36,0],[\"Please choose a calendar\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"ui content\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"calendars\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"ui link list\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"item\"],[12],[2,\"\\n                \"],[10,\"i\"],[15,0,[31,[\"ui \",[32,1,[\"icon\"]],\" \",[32,1,[\"color\"]],\" icon\"]]],[12],[13],[2,\"\\n                \"],[10,\"div\"],[14,0,\"content\"],[12],[2,\"\\n                    \"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n                        \"],[10,\"a\"],[15,6,[32,1,[\"url\"]]],[14,0,\"item active\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"noreferrer nofollow\"],[12],[2,\"\\n                            \"],[1,[32,1,[\"name\"]]],[2,\"\\n                        \"],[13],[2,\"\\n                    \"],[13],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/modals/add-to-calendar.hbs"
    }
  });

  _exports.default = _default;
});