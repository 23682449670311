define("open-event-frontend/authenticators/custom-jwt", ["exports", "ember-simple-auth-token/authenticators/jwt", "open-event-frontend/config/environment"], function (_exports, _jwt, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _jwt.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
    },
    session: Ember.inject.service(),
    handleAuthResponse: function handleAuthResponse(response) {
      var oldRefreshToken = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
      var token = Ember.get(response, this.tokenPropertyName);

      if (Ember.isEmpty(token)) {
        throw new Error('Token is empty. Please check your backend response.');
      }

      var tokenData = this.getTokenData(token);
      var expiresAt = Ember.get(tokenData, this.tokenExpireName);
      var tokenExpireData = {};
      tokenExpireData[this.tokenExpireName] = expiresAt;

      if (this.tokenExpirationInvalidateSession) {
        this.scheduleAccessTokenExpiration(expiresAt);
      }

      if (this.refreshAccessTokens) {
        var refreshToken = null;

        if (oldRefreshToken) {
          refreshToken = oldRefreshToken;
        } else {
          refreshToken = Ember.get(response, this.refreshTokenPropertyName);
        }

        if (Ember.isEmpty(refreshToken)) {
          throw new Error('Refresh token is empty. Please check your backend response.');
        }

        this.scheduleAccessTokenRefresh(expiresAt, refreshToken);
      }

      return Ember.assign(response, tokenExpireData, {
        tokenData: tokenData
      });
    },
    refreshAccessToken: function refreshAccessToken(token) {
      var _this$session$data$au,
          _this = this;

      this.headers[_environment.default['ember-simple-auth-token'].authorizationHeaderName] = _environment.default['ember-simple-auth-token'].authorizationPrefix + token;
      this.headers['X-CSRF-Token'] = (_this$session$data$au = this.session.data.authenticated.tokenData) === null || _this$session$data$au === void 0 ? void 0 : _this$session$data$au.csrf;
      var data = this.makeRefreshData(token);
      return this.makeRequest(this.serverTokenRefreshEndpoint, data, this.headers).then(function (response) {
        var sessionData = _this.handleAuthResponse(response.json, token);

        sessionData.refresh_token = token;

        _this.trigger('sessionDataUpdated', sessionData);

        return sessionData;
      }).catch(function (error) {
        _this.handleTokenRefreshFail(error.status);

        return Ember.RSVP.Promise.reject(error);
      });
    }
  });

  _exports.default = _default;
});