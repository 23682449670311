define("open-event-frontend/templates/admin/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "C7kpxJBR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui grid stackable\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"sixteen wide column\"],[12],[2,\"\\n      \"],[8,\"tables/default\",[],[[\"@columns\",\"@rows\",\"@currentPage\",\"@pageSize\",\"@searchQuery\",\"@sortBy\",\"@sortDir\",\"@metaData\",\"@filterOptions\",\"@hideSearchBox\",\"@widthConstraint\",\"@resizeMode\",\"@fillMode\"],[[32,0,[\"columns\"]],[32,0,[\"model\",\"data\"]],[32,0,[\"page\"]],[32,0,[\"per_page\"]],[32,0,[\"search\"]],[32,0,[\"sort_by\"]],[32,0,[\"sort_dir\"]],[32,0,[\"model\",\"meta\"]],[32,0,[\"filterOptions\"]],true,\"eq-container\",\"fluid\",\"equal-column\"]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\t\\t\"],[10,\"div\"],[15,0,[31,[\"sixteen wide \",[30,[36,0],[[32,0,[\"device\",\"isMobile\"]],\"center aligned\"],null],\" column\"]]],[12],[2,\"\\n\\t\\t  \"],[11,\"button\"],[16,0,[31,[\"ui blue button left floated \",[30,[36,0],[[32,0,[\"isLoading\"]],\"loading\"],null]]]],[4,[38,1],[[32,0],\"save\"],null],[12],[1,[30,[36,2],[\"Save\"],null]],[13],[2,\"\\n\\t\\t\"],[13],[2,\"\\n\\t\"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"t\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/admin/messages.hbs"
    }
  });

  _exports.default = _default;
});