define("open-event-frontend/templates/components/public/exhibitor-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GSw7H43/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"featured-speaker\"],[12],[2,\"\\n            \"],[10,\"img\"],[14,\"alt\",\"exhibitor\"],[14,0,\"ui rounded image\"],[15,\"src\",[31,[[32,0,[\"exhibitor\",\"image\"]]]]],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"h4\"],[14,0,\"ui centered header no margin\"],[12],[2,\"\\n            \"],[10,\"img\"],[14,0,\"ui small image\"],[15,\"src\",[31,[[30,[36,0],[[32,0,[\"exhibitor\",\"logoUrl\"]],\"/images/placeholders/Other.jpg\"],null]]]],[14,\"alt\",\"Logo\"],[12],[13],[2,\"\\n            \"],[1,[32,0,[\"exhibitor\",\"name\"]]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"or\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/public/exhibitor-item.hbs"
    }
  });

  _exports.default = _default;
});