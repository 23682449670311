define("open-event-frontend/templates/public/cfs/edit-speaker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tYZlIkrC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui container mb-4\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column sixteen wide\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"ui header center aligned\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"Edit Speaker\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n      \"],[8,\"forms/session-speaker-form\",[],[[\"@fields\",\"@event\",\"@data\",\"@isLoading\",\"@save\",\"@isSpeaker\",\"@includeSpeaker\"],[[32,0,[\"model\",\"forms\"]],[32,0,[\"model\",\"event\"]],[32,0,[\"model\"]],[32,0,[\"isLoading\"]],[30,[36,1],[[32,0],\"save\"],null],true,true]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/cfs/edit-speaker.hbs"
    }
  });

  _exports.default = _default;
});