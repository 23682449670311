define("open-event-frontend/components/public/social-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['ui', 'basic', 'segment', 'm-0', 'p-0', 'pb-2'],
    socialLinks: Ember.A()
  });

  _exports.default = _default;
});