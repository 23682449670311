define("open-event-frontend/templates/components/ui-table/cell/cell-roles", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "vIdQLfiX",
    "block": "{\"symbols\":[\"role\",\"@record\"],\"statements\":[[10,\"div\"],[14,0,\"ui ordered list\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"item word-break\"],[12],[1,[32,1,[\"user\",\"email\"]]],[2,\" (\"],[1,[30,[36,0],[[32,1,[\"role\",\"titleName\"]]],null]],[2,\")\"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t-var\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/ui-table/cell/cell-roles.hbs"
    }
  });

  _exports.default = _default;
});