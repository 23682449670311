define("open-event-frontend/components/forms/session-section", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/utils/dictionary/languages", "lodash-es", "open-event-frontend/utils/dictionary/levels"], function (_exports, _form, _languages, _lodashEs, _levels) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, {
    languages: (0, _lodashEs.orderBy)(_languages.languages, 'name'),
    levels: (0, _lodashEs.orderBy)(_levels.levels, 'position')
  });

  _exports.default = _default;
});