define("open-event-frontend/templates/events/view/speaker/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "EMM7Q/xN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"column sixteen wide\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"ui header center aligned\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"Edit Speaker\"],null]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n      \"],[8,\"forms/session-speaker-form\",[],[[\"@fields\",\"@data\",\"@save\",\"@event\",\"@isSpeaker\",\"@includeSpeaker\",\"@isSessionSpeaker\",\"@isLoading\"],[[32,0,[\"model\",\"form\"]],[32,0,[\"model\"]],[30,[36,1],[[32,0],\"save\"],null],[32,0,[\"model\",\"event\"]],true,true,true,[32,0,[\"isLoading\"]]]],null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\",\"action\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/events/view/speaker/edit.hbs"
    }
  });

  _exports.default = _default;
});