define("open-event-frontend/components/forms/reset-password-form", ["exports", "open-event-frontend/mixins/form", "open-event-frontend/config/environment"], function (_exports, _form, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_form.default, {
    identification: '',
    password: '',
    isLoading: false,
    router: Ember.inject.service(),
    captcha: false,
    showHcaptcha: !!_environment.default.hcaptchaKey,
    captchaRendered: false,
    getValidationRules: function getValidationRules() {
      return {
        inline: true,
        delay: false,
        on: 'blur',
        fields: {
          identification: {
            identifier: 'email',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your email ID')
            }, {
              type: 'email',
              prompt: this.l10n.t('Please enter a valid email address')
            }]
          },
          password: {
            identifier: 'password',
            rules: [{
              type: 'empty',
              prompt: this.l10n.t('Please enter your new password')
            }, {
              type: 'minLength[8]',
              prompt: this.l10n.t('Your password must have at least {ruleValue} characters')
            }]
          },
          passwordRepeat: {
            identifier: 'password_repeat',
            rules: [{
              type: 'match[password]',
              prompt: this.l10n.t('Passwords do not match')
            }]
          }
        }
      };
    },
    actions: {
      showNewPassword: function showNewPassword() {
        this.toggleProperty('showNewPass');
      },
      showConfirmPassword: function showConfirmPassword() {
        this.toggleProperty('showConfirmPass');
      },
      submit: function submit() {
        var _this = this;

        this.onValid(function () {
          var payload = {};

          if (_this.token) {
            payload = {
              'data': {
                'token': _this.token,
                'password': _this.password
              }
            };

            _this.loader.patch('auth/reset-password', payload).then(function () {
              _this.notify.success(_this.l10n.t('Your password has been reset successfully. Please log in to continue'), {
                id: 'reser_succ'
              });

              _this.router.transitionTo('login');
            }).catch(function (e) {
              console.error('Error while resetting password', e);

              _this.set('errorMessage', _this.l10n.t('Password reset link is either invalid or used already'), {
                id: 'reset_invalid'
              });
            }).finally(function () {
              _this.set('isLoading', false);

              _this.set('token', null);
            });
          } else {
            payload = {
              'data': {
                'email': _this.identification
              }
            };

            _this.loader.post('auth/reset-password', payload).then(function () {
              _this.notify.success(_this.l10n.t('Please go to the link sent to your email to reset your password'), {
                id: 'reset_link_sent'
              });

              _this.router.transitionTo('login');
            }).catch(function (reason) {
              if (reason && Object.prototype.hasOwnProperty.call(reason, 'errors') && reason.errors[0].status === 404) {
                console.warn('Reset Password: No user account found', reason);

                _this.set('errorMessage', _this.l10n.t('No account is registered with this email address.'));
              } else {
                console.error('Error while submitting reset password', reason);

                _this.set('errorMessage', _this.l10n.t('An unexpected error has occurred.'));
              }
            }).finally(function () {
              _this.set('isLoading', false);
            });
          }

          _this.set('errorMessage', null);

          _this.set('successMessage', null);

          _this.set('isLoading', true);
        });
      }
    },
    didInsertElement: function didInsertElement() {
      if (this.session.newUser) {
        this.set('newUser', this.session.newUser);
        this.set('identification', this.session.newUser);
        this.set('session.newUser', null);
      }
    }
  });

  _exports.default = _default;
});