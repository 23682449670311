define("open-event-frontend/templates/public/exhibition/view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V47uyxm8",
    "block": "{\"symbols\":[],\"statements\":[[8,\"public/exhibition-view\",[],[[\"@data\"],[[32,0,[\"model\"]]]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/public/exhibition/view.hbs"
    }
  });

  _exports.default = _default;
});