define("open-event-frontend/templates/components/public/featured-speaker-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m4BvvQ26",
    "block": "{\"symbols\":[\"speaker\",\"@timezone\"],\"statements\":[[10,\"h2\"],[14,0,\"mt-16\"],[12],[1,[30,[36,0],[\"Featured Speakers\"],null]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"speaker-list ui three column grid\"],[12],[2,\"\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[32,0,[\"speakers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,\"public/speaker-item\",[],[[\"@speaker\",\"@timezone\",\"@class\",\"@isFeatured\"],[[32,1],[32,2],\"column\",true]],null],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,5],[[30,[36,4],[[30,[36,3],[[32,0,[\"allSpeakers\"]],[32,0,[\"speakers\",\"meta\",\"count\"]]],null],0],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"ui container\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"ui centered grid\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"ui blue button\"]],[[\"@route\"],[\"public.speakers\"]],[[\"default\"],[{\"statements\":[[1,[30,[36,0],[\"More Speakers\"],null]]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"t\",\"-track-array\",\"each\",\"sub\",\"gt\",\"if\"]}",
    "meta": {
      "moduleName": "open-event-frontend/templates/components/public/featured-speaker-list.hbs"
    }
  });

  _exports.default = _default;
});