define("open-event-frontend/models/speaker-invite", ["exports", "ember-data/attr", "open-event-frontend/models/base", "moment", "ember-data/relationships"], function (_exports, _attr, _base, _moment, _relationships) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var detectedTimezone = _moment.default.tz.guess();

  var _default = _base.default.extend({
    email: (0, _attr.default)('string'),
    status: (0, _attr.default)('string', {
      defaultValue: 'pending'
    }),
    createdAt: (0, _attr.default)('moment', {
      defaultValue: function defaultValue() {
        return _moment.default.tz(detectedTimezone);
      }
    }),
    event: (0, _relationships.belongsTo)('event'),
    session: (0, _relationships.belongsTo)('session')
  });

  _exports.default = _default;
});