define("open-event-frontend/components/ui-table/cell/admin/sales/cell-status-action", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <UiDropdown @class="ui floating dropdown button w-content-button">
    <div class="ui blue empty circular label"></div>
    {{t-var (capitalize @record)}}
    <div class="menu">
      {{#each @props.options.paymentStateMap as |state|}}
        <div class="item" role="button" {{action @props.actions.changeState @extraRecords.id state.name}}>
          <div class="ui {{state.color}} empty circular label"></div>
          {{t-var (capitalize state.name)}}
        </div>
      {{/each}}
    </div>
  </UiDropdown>
  
  */
  {
    "id": "kYPsD6Ue",
    "block": "{\"symbols\":[\"state\",\"@extraRecords\",\"@props\",\"@record\"],\"statements\":[[8,\"ui-dropdown\",[],[[\"@class\"],[\"ui floating dropdown button w-content-button\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"ui blue empty circular label\"],[12],[13],[2,\"\\n  \"],[1,[30,[36,2],[[30,[36,1],[[32,4]],null]],null]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"menu\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[32,3,[\"options\",\"paymentStateMap\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[24,0,\"item\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],[32,3,[\"actions\",\"changeState\"]],[32,2,[\"id\"]],[32,1,[\"name\"]]],null],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"ui \",[32,1,[\"color\"]],\" empty circular label\"]]],[12],[13],[2,\"\\n        \"],[1,[30,[36,2],[[30,[36,1],[[32,1,[\"name\"]]],null]],null]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"capitalize\",\"t-var\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "open-event-frontend/components/ui-table/cell/admin/sales/cell-status-action.hbs"
    }
  });

  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());

  _exports.default = _default;
});